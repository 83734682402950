<template>
  <div>
    <CRow>
      <CCol sm="12">
        <ConceptsTable
          hover
          striped
          border
          small
          fixed
          caption="Nuevo Apartado"
        />
      </CCol>
    </CRow>
  </div>
</template>
<script>

import store from '../../store'
import ConceptsTable from '../../components/sales/ConceptsTable.vue'

export default {
  name: 'PreSale',
  components: {
    ConceptsTable
  },
  data () {
    return {

    }
  },
  mounted: async function() {

  },
  methods: {

  }
}
</script>
